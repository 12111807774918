import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Card,
  CardContent,
  CardActions,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { createProdotto, updateProdotto } from "../services/prodottiService";
import { getFornitoris } from "../services/fornitoriService";
import {
  createProdottoFornitorePrezzo,
  deleteProdottoFornitorePrezzo,
  updateProdottoFornitorePrezzo,
} from "../services/prodottiFornitoriPrezziService";
import { Fornitore } from "../models/Fornitore";
import { ProdottoView } from "../models/Prodotto";
import { ProdottoFornitorePrezzo } from "../models/ProdottoFornitorePrezzo";
import {
  Delete,
  Edit,
  EditNotifications,
  Euro,
  Save,
} from "@mui/icons-material";

interface ProdottoFormProps {
  onClose: () => void;
  onSuccess: () => void;
  prodotto?: ProdottoView;
}

const ProdottoForm: React.FC<ProdottoFormProps> = ({
  onClose,
  onSuccess,
  prodotto,
}) => {
  const [fornitori, setFornitori] = useState<Fornitore[]>([]);
  const [codice, setCodice] = useState(prodotto?.codice || "");
  const [descrizione, setDescrizione] = useState(prodotto?.descrizione || "");
  const [prezzo, setPrezzo] = useState(prodotto?.prezzo?.toString() || "");
  const [attivo, setAttivo] = useState(prodotto?.attivo || false);
  const [prezziFornitori, setPrezziFornitori] = useState<
    ProdottoFornitorePrezzo[]
  >(prodotto?.prezziFornitori || []);
  const [editing, setEditing] = useState<{ [key: number]: boolean }>({});
  const [addedPrezziFornitori, setAddedPrezziFornitori] = useState<
    ProdottoFornitorePrezzo[]
  >([]);
  // const [fornitoreDetails, setFornitoreDetails] = useState<{
  //   [key: number]: { urlPagina: string; selectorHtml: string };
  // }>({});
  // const [extracting, setExtracting] = useState(false);
  // const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    resetForm();
    fetchFornitori();
  }, []);

  const fetchFornitori = async () => {
    const fornitoriData = await getFornitoris();
    setFornitori(fornitoriData);
  };

  const resetForm = () => {
    setCodice(prodotto?.codice || "");
    setDescrizione(prodotto?.descrizione || "");
    setPrezzo(prodotto?.prezzo?.toString() || "");
  };

  const handleSubmit = async () => {
    try {
      const insertedProdotto = prodotto
        ? await updateProdotto(prodotto.id, {
            ...prodotto,
            codice,
            descrizione,
            attivo: attivo,
            prezzo: parseFloat(prezzo),
          })
        : await createProdotto({
            codice,
            descrizione,
            attivo: attivo,
            prezzo: parseFloat(prezzo),
          });

      await Promise.all(
        addedPrezziFornitori.map((pf) =>
          createProdottoFornitorePrezzo({
            ...pf,
            prodottoId: insertedProdotto.id,
          })
        )
      );

      await Promise.all(
        prezziFornitori.map((pf) =>
          updateProdottoFornitorePrezzo(pf.id ?? -1, pf)
        )
      );

      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error creating prodotto:", error);
    }
  };

  const handleEdit = (prezziFornitori: ProdottoFornitorePrezzo) => {
    setEditing((prev) => ({ ...prev, [prezziFornitori.id ?? -1]: true }));
  };

  const handleSave = async (prezziFornitori: ProdottoFornitorePrezzo) => {
    await updateProdottoFornitorePrezzo(prezziFornitori.id ?? -1, prezziFornitori);
    setEditing((prev) => ({ ...prev, [prezziFornitori.id ?? -1]: false }));
    // try {
    //   const updatedPrezzo = editedPrezzi[id];
    //   await updateProdottoFornitorePrezzo(id, updatedPrezzo);
    //   setEditing((prev) => ({ ...prev, [id]: false }));
    //   onSuccess();
    // } catch (error) {
    //   console.error('Error updating prezzo:', error);
    // }
  };
  const handleSaveNew = async (
    prezziFornitori: ProdottoFornitorePrezzo,
    index: number
  ) => {
    // try {
    //   const updatedPrezzo = editedPrezzi[id];
    //   await updateProdottoFornitorePrezzo(id, updatedPrezzo);
    //   setEditing((prev) => ({ ...prev, [id]: false }));
    //   onSuccess();
    // } catch (error) {
    //   console.error('Error updating prezzo:', error);
    // }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteProdottoFornitorePrezzo(id);
      onSuccess();
    } catch (error) {
      console.error('Error deleting prezzo:', error);
    }
  };

  const handleDeleteNew = async (
    prezziFornitoriObject: ProdottoFornitorePrezzo,
    index: number
  ) => {
    const updatedPrezziFornitori = [...addedPrezziFornitori];
    updatedPrezziFornitori.splice(index, 1);
    setAddedPrezziFornitori(updatedPrezziFornitori);
  };
  const handleChange = (
    object: ProdottoFornitorePrezzo,
    field: keyof ProdottoFornitorePrezzo,
    value: string | number
  ) => {
    const updatedPrezziFornitori = [...prezziFornitori];
    const index = updatedPrezziFornitori.findIndex((pf) => pf.id === object.id);
    updatedPrezziFornitori[index] = {
      ...object,
      [field]: value,
    };
    setPrezziFornitori(updatedPrezziFornitori);
  };
  const handleChangeNew = (
    object: ProdottoFornitorePrezzo,
    index: number,
    field: keyof ProdottoFornitorePrezzo,
    value: string | number
  ) => {
    const updatedPrezziFornitori = [...addedPrezziFornitori];
    updatedPrezziFornitori[index] = {
      ...object,
      [field]: value,
    };
    setAddedPrezziFornitori(updatedPrezziFornitori);
  };

  const handleAddPrezzoFornitore = () => {
    setAddedPrezziFornitori((prev) => [
      ...prev,
      {
        prezzo: 0,
        urlPagina: "",
        selectorHtml: "",
        dataIns: "",
        cProdottoFornitore: "",
        esitoEstrazione: "",
        fornitoreId: 0,
        prodottoId: prodotto?.id || 0,
        fornitore: fornitori[0],
        prodotto: prodotto,
      },
    ]);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            {prodotto
              ? prodotto.codice + " " + prodotto.descrizione
              : "Add New Prodotto"}
          </Box>
        </Typography>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            label="Codice"
            value={codice}
            onChange={(e) => setCodice(e.target.value)}
            required
          />
          <TextField
            label="Descrizione"
            value={descrizione}
            onChange={(e) => setDescrizione(e.target.value)}
            required
          />
          <TextField
            label="Prezzo"
            type="number"
            value={prezzo}
            onChange={(e) => setPrezzo(e.target.value)}
            required
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={attivo}
                onChange={(e) => setAttivo(e.target.checked)}
              />
            }
            label="Attivo"
          />
          {prodotto?.id ? (
            <Typography variant="h5" component="div" gutterBottom>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                Fornitori coinvolti: {prodotto?.prezziFornitori?.length}{" "}
                {prodotto?.prezziFornitori
                  .map((pf) => pf.fornitore?.descrizione)
                  .join(", ")}
              </Box>
            </Typography>
          ) : null}
          {prodotto?.id && prezziFornitori && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Prezzi Fornitori</Typography>
                <Box>
                  <Button
                    onClick={handleAddPrezzoFornitore}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Add Prezzo Fornitore
                  </Button>
                </Box>
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Actions</TableCell>
                      <TableCell>Prodotto</TableCell>
                      <TableCell>Fornitore</TableCell>
                      <TableCell>Prezzo</TableCell>
                      <TableCell>Prezzo Sconto</TableCell>
                      <TableCell>URL Pagina</TableCell>
                      <TableCell>Selector HTML</TableCell>
                      <TableCell>cProdottoFornitore</TableCell>
                      <TableCell>Data Ins</TableCell>
                      <TableCell>esitoEstrazione</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prezziFornitori.map((pf) => (
                      <TableRow key={pf.id}>
                        <TableCell>{pf.id}</TableCell>
                        <TableCell>
                          {/* {editing[pf.id] ? (
                            <IconButton
                              onClick={() => handleSave(pf.id)}
                              color="primary"
                            >
                              <SaveIcon />
                            </IconButton>
                          ) : ( */}
                          {editing[pf.id ?? -1] ? (
                            <IconButton
                              onClick={() => handleSave(pf)}
                              color="primary"
                            >
                              <Save />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleEdit(pf)}
                              color="primary"
                            >
                              <Edit />
                            </IconButton>
                          )}

                          {pf.id ? (
                            <IconButton
                              onClick={() => handleDelete(pf.id ?? -1)}
                              color="primary"
                            >
                              <Delete />
                            </IconButton>
                          ) : null}
                        </TableCell>
                        <TableCell>{pf.prodotto?.descrizione}</TableCell>
                        <TableCell>
                          {editing[pf.id ?? -1] ? (
                            <FormControl fullWidth>
                              <InputLabel>Fornitore</InputLabel>
                              <Select
                                value={pf?.fornitoreId || ""}
                                onChange={(e) =>
                                  handleChange(
                                    pf,
                                    "fornitoreId",
                                    parseInt(e.target.value as string)
                                  )
                                }
                                input={<OutlinedInput label="Fornitore" />}
                              >
                                {fornitori.map((fornitore) => (
                                  <MenuItem
                                    key={fornitore.id ?? -1}
                                    value={fornitore.id ?? -1}
                                  >
                                    {fornitore.descrizione}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            pf.fornitore?.descrizione
                          )}
                        </TableCell>
                        <TableCell>
                          {editing[pf.id ?? -1] ? (
                            <TextField
                              value={pf?.prezzo || ""}
                              onChange={(e) =>
                                handleChange(
                                  pf,
                                  "prezzo",
                                  parseFloat(e.target.value)
                                )
                              }
                              fullWidth
                            />
                          ) : (
                            <>
                              <Euro sx={{ verticalAlign: "middle", mr: 1 }} />
                              {pf.prezzo}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {editing[pf.id ?? -1] ? (
                            <TextField
                              value={pf?.prezzoSconto || ""}
                              onChange={(e) =>
                                handleChange(
                                  pf,
                                  "prezzoSconto",
                                  parseFloat(e.target.value)
                                )
                              }
                              fullWidth
                            />
                          ) : (
                            <>
                              <Euro sx={{ verticalAlign: "middle", mr: 1 }} />
                              {pf.prezzoSconto}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {editing[pf.id ?? -1] ? (
                            <TextField
                              value={pf?.urlPagina || ""}
                              onChange={(e) =>
                                handleChange(pf, "urlPagina", e.target.value)
                              }
                              fullWidth
                            />
                          ) : (
                            pf.urlPagina
                          )}
                        </TableCell>
                        <TableCell>
                          {editing[pf.id ?? -1] ? (
                            <TextField
                              value={pf?.selectorHtml || ""}
                              onChange={(e) =>
                                handleChange(pf, "selectorHtml", e.target.value)
                              }
                              fullWidth
                            />
                          ) : (
                            pf.selectorHtml
                          )}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {editing[pf.id ?? -1] ? (
                            <TextField
                              value={pf?.cProdottoFornitore || ""}
                              onChange={(e) =>
                                handleChange(
                                  pf,
                                  "cProdottoFornitore",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          ) : (
                            pf.cProdottoFornitore
                          )}
                        </TableCell>
                        <TableCell>{pf.dataIns}</TableCell>
                        <TableCell>{pf.esitoEstrazione}</TableCell>
                      </TableRow>
                    ))}
                    {addedPrezziFornitori.map((pf, index) => (
                      <TableRow key={index}>
                        <TableCell></TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleDeleteNew(pf, index)}
                            color="primary"
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                        <TableCell>{pf.prodotto?.descrizione}</TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            <InputLabel>Fornitore</InputLabel>
                            <Select
                              value={pf?.fornitoreId || ""}
                              onChange={(e) =>
                                handleChangeNew(
                                  pf,
                                  index,
                                  "fornitoreId",
                                  parseInt(e.target.value as string)
                                )
                              }
                              input={<OutlinedInput label="Fornitore" />}
                            >
                              {fornitori.map((fornitore) => (
                                <MenuItem
                                  key={fornitore.id ?? -1}
                                  value={fornitore.id ?? -1}
                                >
                                  {fornitore.descrizione}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={pf?.prezzo || ""}
                            onChange={(e) =>
                              handleChangeNew(
                                pf,
                                index,
                                "prezzo",
                                parseFloat(e.target.value)
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={pf?.urlPagina || ""}
                            onChange={(e) =>
                              handleChangeNew(
                                pf,
                                index,
                                "urlPagina",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={pf?.selectorHtml || ""}
                            onChange={(e) =>
                              handleChangeNew(
                                pf,
                                index,
                                "selectorHtml",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={pf?.cProdottoFornitore || ""}
                            onChange={(e) =>
                              handleChangeNew(
                                pf,
                                index,
                                "cProdottoFornitore",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>{pf.dataIns}</TableCell>
                        <TableCell>{pf.esitoEstrazione}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProdottoForm;
