import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TablePagination,
  Box,
  Drawer,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { FileCaricati } from "../models/FileCaricati";
import { fetchFiles } from "../services/fileCaricatiService";
import { getFornitoris } from "../services/fornitoriService";
import { Fornitore } from "../models/Fornitore";
import ProdottoFormDialog from "./ProdottoFormDialog";
import { Refresh, Search } from "@mui/icons-material";
import FileCaricatiFilter from "./FileCaricatiFilter";

const FileCaricatiComponent = () => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [queryParams, setQueryParams] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [files, setFiles] = useState<FileCaricati[]>([]);
  const [fornitori, setFornitori] = useState<Fornitore[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    fetchData(
      queryParams +
        (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
    );
  }, [page, rowsPerPage, orderBy, orderDirection]);

  useEffect(() => {
    const fetchFornitori = async () => {
      try {
        const data = await getFornitoris();
        setFornitori(data);
      } catch (error) {
        console.error("Errore nel recupero dei dati:", error);
      }
    };
    fetchFornitori();
  }, []);

  const fetchData = async (queryParams: string) => {
    setLoading(true);
    try {
      const data = await fetchFiles(page, rowsPerPage, queryParams);
      setFiles(data.content);
      setTotalRows(data.page.totalElements);
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (newQueryParams: string) => {
    // Implementa la logica di filtro qui
    setQueryParams(newQueryParams);
    setIsFilterDrawerOpen(false);
    fetchData(
      newQueryParams +
        (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  interface RowsPerPageChangeEvent extends React.ChangeEvent<HTMLInputElement> {
    target: HTMLInputElement & { value: string };
  }

  const handleChangeRowsPerPage = (event: RowsPerPageChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
      >
        <FileCaricatiFilter
          handleFilter={handleFilter}
          fornitori={fornitori}
          queryParams={queryParams}
        />
      </Drawer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography component="h2" variant="h6">
          File caricati
        </Typography>
        <Box>
          <IconButton onClick={() => fetchData(queryParams)} color="primary">
            <Refresh />
          </IconButton>
          <IconButton
            onClick={() => setIsFilterDrawerOpen(true)}
            color="primary"
          >
            <Search />
          </IconButton>
        </Box>
      </Box>
      <Card sx={{ mb: 2, borderRadius: 2, border: "1px solid #40a7f2" }}>
        <CardContent>
          <TableContainer component={Paper} sx={{ maxHeight: "70vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sortDirection={
                      orderBy === "nomeFile" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "nomeFile"}
                      direction={
                        orderBy === "nomeFile" ? orderDirection : "asc"
                      }
                      onClick={() => handleSort("nomeFile")}
                    >
                      Nome File
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "numRighe" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "numRighe"}
                      direction={
                        orderBy === "numRighe" ? orderDirection : "asc"
                      }
                      onClick={() => handleSort("numRighe")}
                    >
                      Numero Righe
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "dataCaricamento" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "dataCaricamento"}
                      direction={
                        orderBy === "dataCaricamento" ? orderDirection : "asc"
                      }
                      onClick={() => handleSort("dataCaricamento")}
                    >
                      Data Caricamento
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "idFornitore" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "idFornitore"}
                      direction={
                        orderBy === "idFornitore" ? orderDirection : "asc"
                      }
                      onClick={() => handleSort("idFornitore")}
                    >
                      ID Fornitore
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell>{file.nomeFile}</TableCell>
                    <TableCell>{file.numRighe}</TableCell>
                    <TableCell>{file.dataCaricamento}</TableCell>
                    <TableCell>{file.fornitore.descrizione}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 200, 500]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default FileCaricatiComponent;
