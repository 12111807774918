import axios from "axios";
import { Fornitore } from "../models/Fornitore";
import { API_URL } from "../constants";

export const getFornitoris = async (): Promise<Fornitore[]> => {
  const response = await axios.get(API_URL + "/fornitoris");
  return response.data;
};

export const createFornitore = async (
  fornitore: Fornitore
): Promise<Fornitore> => {
  const response = await axios.post(API_URL + "/fornitoris", fornitore);
  return response.data;
};
export const updateFornitore = async (
  id: number,
  fornitore: Fornitore
): Promise<Fornitore> => {
  const response = await axios.put(API_URL + "/fornitoris/" + id, fornitore);
  return response.data;
};
