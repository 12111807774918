import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import { FileCaricatiFilters } from "../models/Page";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Fornitore } from "../models/Fornitore";
import { it } from "date-fns/locale";
import { parse } from "date-fns";

interface FileCaricatiFilterProps {
  queryParams: string;
  handleFilter: (queryParams: string) => void;
  fornitori: Fornitore[];
}
const FileCaricatiFilter: React.FC<FileCaricatiFilterProps> = ({
  handleFilter,
  queryParams,
  fornitori,
}) => {
  const [filters, setFilters] = useState({
    nomeFile: "",
    dataCaricamentoInizio: "",
    dtDataCaricamentoInizio: null,
    dataCaricamentoFine: "",
    dtDataCaricamentoFine: null,
    idFornitore: "",
  });
  React.useEffect(() => {
    const params = new URLSearchParams(queryParams);
    const newFilters: any = {};
    params.forEach((value, key) => {
      newFilters[key] = value;
    });
    newFilters.dtDataCaricamentoInizio =
      newFilters.dataCaricamentoInizio != null
        ? parseDate(newFilters.dataCaricamentoInizio)
        : null;
    newFilters.dtDataCaricamentoFine =
      newFilters.dataCaricamentoFine != null
        ? parseDate(newFilters.dataCaricamentoFine)
        : null;
    setFilters(newFilters);
  }, [queryParams]);

  const parseDate = (dateString: string): Date | null => {
    return parse(dateString, "dd-MM-yyyy", new Date());
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const submitFilter = () => {
    handleFilter(buildQueryParams(filters));
  };

  const buildQueryParams = (filters: FileCaricatiFilters): string => {
    const params = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (!key.startsWith("dt") && filters[key as keyof FileCaricatiFilters]) {
        params.append(key, filters[key as keyof FileCaricatiFilters]);
      }
    });
    return params.toString();
  };
  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleDateChange = (
    name: string,
    dtName: string,
    date: Date | null
  ) => {
    setFilters({
      ...filters,
      [name]: date ? formatDate(date) : "",
      [dtName]: date,
    });
  };

  const resetFilter = () => {
    handleFilter("");
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleFilter(buildQueryParams(filters));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <Box p={2} width="300px">
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl fullWidth>
              <TextField
                label="Nome File"
                name="nomeFile"
                value={filters.nomeFile}
                onChange={handleFilterChange}
                fullWidth
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    submitFilter();
                  }
                }}
                margin="normal"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                select
                name="idFornitore"
                label="Select Fornitore"
                value={filters.idFornitore}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
              >
                {fornitori.map((fornitore) => (
                  <MenuItem
                    key={fornitore.id?.toString()}
                    value={fornitore.id?.toString()}
                    selected={fornitore.id?.toString() === filters.idFornitore}
                  >
                    {fornitore.descrizione}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                label="Caricamento Inizio"
                name="dataCaricamentoInizio"
                sx={{
                  marginBottom: "8px",
                }}
                value={
                  filters.dtDataCaricamentoInizio
                    ? new Date(filters.dtDataCaricamentoInizio)
                    : null
                }
                onChange={(date: Date | null) =>
                  handleDateChange(
                    "dataCaricamentoInizio",
                    "dtDataCaricamentoInizio",
                    date
                  )
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                label="Caricamento Fine"
                name="dataCaricamentoFine"
                sx={{
                  marginBottom: "8px",
                }}
                value={
                  filters.dtDataCaricamentoFine
                    ? new Date(filters.dtDataCaricamentoFine)
                    : null
                }
                onChange={(date: Date | null) =>
                  handleDateChange(
                    "dataCaricamentoFine",
                    "dtDataCaricamentoFine",
                    date
                  )
                }
              />
            </FormControl>

            <Button onClick={submitFilter} color="primary" variant="contained">
              Applica Filtri
            </Button>
            <Button onClick={resetFilter} color="secondary" variant="contained">
              Reset
            </Button>
          </FormGroup>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
export default FileCaricatiFilter;
