import axios from "axios";
import { ProdottoFornitorePrezzo } from "../models/ProdottoFornitorePrezzo";
import { API_URL } from "../constants";

export const getProdottiFornitoriPrezzis = async (): Promise<
  ProdottoFornitorePrezzo[]
> => {
  const response = await axios.get(`${API_URL}/prodottiFornitoriPrezzis`);
  return response.data;
};

export const createProdottoFornitorePrezzo = async (
  prodottoFornitorePrezzo: ProdottoFornitorePrezzo
): Promise<ProdottoFornitorePrezzo> => {
  const response = await axios.post(
    `${API_URL}/prodottiFornitoriPrezzis`,
    prodottoFornitorePrezzo
  );
  return response.data;
};
export const updateProdottoFornitorePrezzo = async (
  id: number,
  prodottoFornitorePrezzo: ProdottoFornitorePrezzo
): Promise<ProdottoFornitorePrezzo> => {
  const response = await axios.put(
    `${API_URL}/prodottiFornitoriPrezzis/` + id,
    prodottoFornitorePrezzo
  );
  return response.data;
};

export const deleteProdottoFornitorePrezzo = async (
  id: number
): Promise<void> => {
  await axios.delete(`${API_URL}/prodottiFornitoriPrezzis/` + id);
};
