import * as React from "react";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "./components/AppNavbar";
import SideMenu from "./components/SideMenu";
import AppTheme from "./AppTheme";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Fornitori from "./components/Fornitori";
import Products from "./components/Products";
import ProductDetail from "./components/ProductDetail";
import FileCaricatiComponent from "./components/FileCaricatiComponent";

export default function App() {
  return (
    <BrowserRouter>
      <AppTheme>
        <Box sx={{ display: "flex" }}>
          <SideMenu />
          <Box
            component="main"
            sx={(theme) => ({
              flexGrow: 1,
              backgroundColor: alpha(theme.palette.background.default, 1),
              overflow: "auto",
            })}
          >
            <AppNavbar />
            <Stack
              spacing={2}
              sx={{
                alignItems: "center",
                mx: 3,
                pb: 5,
                mt: { xs: 8, md: 0 },
              }}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/products" />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/:id" element={<ProductDetail />} />
                <Route path="/fornitori" element={<Fornitori />} />
                <Route path="/fileCaricati" element={<FileCaricatiComponent />} />
                {/* Add more routes here */}
              </Routes>
            </Stack>
          </Box>
        </Box>
      </AppTheme>
    </BrowserRouter>
  );
}
