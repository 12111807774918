import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Link, useLocation } from "react-router-dom";
import { LocalShipping, ProductionQuantityLimits, UploadFile } from "@mui/icons-material";

const mainListItems = [
  { text: "Products", icon: <ProductionQuantityLimits />, route: "/products" },
  { text: "Fornitori", icon: <LocalShipping />, route: "/fornitori" },
  { text: "File caricati", icon: <UploadFile />, route: "/fileCaricati" },
];

export default function MenuContent() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              component={Link}
              to={item.route}
              selected={currentPath === item.route}
              sx={{ cursor: "pointer" }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
